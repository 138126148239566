import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import Navbar from '../Navbar';
import './index.scss';

import birthday from '../../assets/images/birthday.jpg';
import fish from '../../assets/images/fish.jpg';
import wedding from '../../assets/images/wedding.jpg';

import blurryTaillight from '../../assets/images/blurry-taillights.JPG';
import cowFocused from '../../assets/images/cow-focused.JPG';
import cowUnfocused from '../../assets/images/cow-unfocused.JPG';
import parliament from '../../assets/images/parliament-darkblue.JPG';
import parliamentNight from '../../assets/images/parliament-night.JPG';
import treeLeaves from '../../assets/images/tree-leaves.jpg';

import healesvilleRiver from '../../assets/images/healesville-river.jpg';
import langham from '../../assets/images/langham-view.jpg';
import caesarsPalace from '../../assets/images/caesars-palace-roof.jpg';
import litTree from '../../assets/images/lit-tree.jpg';
import santaMonica from '../../assets/images/santa-monica.jpg';

import nova from '../../assets/images/nova.jpg';
import pandoraFunny from '../../assets/images/pandora-funny.jpg';
import roarCar from '../../assets/images/roar-car.jpg';
import roarComputer from '../../assets/images/roar-computer.jpg';
import toothless from '../../assets/images/toothless.jpg';

function Home() {
    const [showModal, setShowModal] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    const [activeBox, setActiveBox] = useState(null);
    const [loadedImages, setLoadedImages] = useState({});

    const imagesToLoad = [blurryTaillight, cowFocused, cowUnfocused, parliament, parliamentNight, treeLeaves, healesvilleRiver, langham, caesarsPalace, litTree, santaMonica, birthday, fish, wedding, nova, pandoraFunny, roarCar, roarComputer, toothless];

    useEffect(() => {
		imagesToLoad.forEach(src => {
			const img = new Image();
			img.src = src;
			img.onload = () => {
				setLoadedImages(prev => ({ ...prev, [src]: true }));
			};
		});
	}, []);	

    const allImagesLoaded = imagesToLoad.every(src => loadedImages[src]);

    const modalData = {
		0: {
			images: [
				{ src: birthday, position: '50% 50%' },
				{ src: wedding, position: '50% 50%' },
				{ src: fish, position: '50% 50%' },
			],
		},
		1: {
			images: [
				{ src: cowFocused, position: '50% 50%' },
				{ src: blurryTaillight, position: '50% 50%' },
				{ src: parliamentNight, position: '50% 50%' },
				{ src: cowUnfocused, position: '50% 50%' },
				{ src: treeLeaves, position: '50% 50%' },
				{ src: parliament, position: '50% 50%' },
			],
		},
		2: {
			images: [
				{ src: langham, position: '50% 50%' },
				{ src: caesarsPalace, position: '50% 50%' },
				{ src: litTree, position: '50% 50%' },
				{ src: santaMonica, position: '50% 50%' },
				{ src: healesvilleRiver, position: '50% 50%' },
			],
		},
		3: {
			images: [
				{ src: nova, position: '50% 42.5%' },
				{ src: pandoraFunny, position: '50% 53%' },
				{ src: roarCar, position: '50% 40%' },
				{ src: roarComputer, position: '50% 70%' },
				{ src: toothless, position: '50% 50%' },
			],
		},
	};

	useEffect(() => {
        if (fadeOut) {
            setTimeout(() => {
                setShowModal(false);
                setFadeOut(false); 
            }, 500); 
        }
    }, [fadeOut]);

    return (
        <>
            <Navbar close={() => {
                setFadeOut(true);
                setTimeout(() => setShowModal(false), 500);
            }} isVisible={showModal} />
            <div className="home" style={{ opacity: allImagesLoaded ? 1 : 0, transition: 'opacity 1s' }}>
                <div className="boxes">
                    {[...Array(4)].map((_, index) => (
                        <div key={index} className="box" onClick={() => {
                            setActiveBox(index);
                            setShowModal(true);
                        }}></div>
                    ))}
                </div>
                {showModal && <Modal data={modalData[activeBox]} fadeOut={fadeOut} />}
            </div>
        </>
    );
}

export default Home;
