import React, { useState, useEffect } from 'react';
import './index.scss';

function Modal({ data, fadeOut, onClose }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(true);
    const images = data?.images || [];

    useEffect(() => {
        if (shouldRender && !fadeOut) {
            setIsVisible(true);
        }
        if (fadeOut) {
            setIsVisible(false);
            setTimeout(() => setShouldRender(false), 500);  // matches the transition duration
        }
    }, [fadeOut, shouldRender]);

    useEffect(() => {
        if (!fadeOut) {
            setShouldRender(true);
            setTimeout(() => setIsVisible(true), 50);  // small delay to trigger fade-in
        }
    }, [fadeOut]);

    const goToNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPreviousImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    if (!shouldRender) {
        return null;
    }

    return (
        <div className={`modal-overlay ${isVisible ? 'visible' : 'hidden'}`} onClick={onClose}>
            <div className="bg-container">
                <div className="background"></div>
            </div>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                {images.length > 0 && (
                    <img
                        src={images[currentImageIndex].src}
                        alt="Modal content"
                        style={{ objectPosition: images[currentImageIndex].position }}
                    />
                )}
                {images.length > 1 && (
                    <>
                        <button className="prev-button" onClick={goToPreviousImage}>
						&#9675;
                        </button>
                        <button className="next-button" onClick={goToNextImage}>
							&#9679;
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}

export default Modal;