import './index.scss';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png'

const Navbar = ({ close, isVisible }) => (
    <div className={`nav-bar ${isVisible ? 'modal-open' : ''}`}>
        <Link className='logo' to='/' onClick={close}>
            <img src={Logo} alt='logo' />
        </Link>
    </div>
);


export default Navbar;
